import React, {useState} from 'react';
import {Button} from "@mui/material";
import {CubeCaptcha} from "./components/cube-captcha";

export default function App() {
    const [url, setUrl] = useState("")
    return (
        <>

            <CubeCaptcha
                api="https://api.captcha.cubestudio.top:5100/"
            onAuth={(result)=>{
                if(result){
                    setUrl("https://vdse.bdstatic.com/192d9a98d782d9c74c96f09db9378d93.mp4")
                }
            }}/>
            <div>
                <video src={url} style={{ width: '100%', height: '100%'}} autoPlay />
            </div>

        </>
    );
}
