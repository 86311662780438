import {Box, Modal, Typography, Grid, Button, Theme, SxProps} from "@mui/material";
import {LoadingButton} from '@mui/lab'
import {Close, People as PeopleIcon, Refresh, Check} from "@mui/icons-material";
import React, {useEffect, useRef, useState} from "react";
import {AnimatePresence, motion, MotionStyle} from "framer-motion";
import axios from "axios";

interface CubeCaptchaModalProps {
    api: string
    show?: boolean
    onHide?: Function
    onAuth?: { bivarianceHack(result:boolean): void; }['bivarianceHack']
}

class CubeCaptchaPoint {
    left: number
    top: number
    x: number
    y: number

    constructor(left: number,top: number,x: number, y: number) {
        this.left = left
        this.top = top
        this.x = x
        this.y = y
    }
}

const boxStyle: SxProps<Theme> = {
    position: 'absolute',
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    margin: 'auto',
    maxWidth: 360,
    height: 'fit-content',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 2,
    userSelect: 'none'
};
const buttonStyle: SxProps<Theme> = {
    width: '30px',
    height: '30px',
    color: 'primary.main',
    opacity: '0.7',
    transition: 'all 0.4s cubic-bezier(.17,.84,.44,1)',
    cursor: 'pointer',
    ":hover": {
        opacity: '1',
        transform: 'rotate(90deg)'
    }
}
const pointStyle: MotionStyle = {
    position: 'absolute',
    width: '26px',
    height: '26px',
    borderRadius: '50%',
    fontSize: '14px',
    backgroundColor: 'rgba(97,160,255)',
    color: '#ffffff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderStyle: 'solid',
}
const gap = 10

export function CubeCaptcha({api,onAuth}: CubeCaptchaModalProps){
    const [captchaState, setCaptchaState] = useState(false)
    return (
        <>
            <LoadingButton
                variant="contained"
                disableElevation
                loadingPosition="start"
                loading={captchaState}
                startIcon={<PeopleIcon/>}
                onClick={() => {
                setCaptchaState(true)
            }}>
                我是人
            </LoadingButton>
            <CubeCaptchaModal
                api={api}
                show={captchaState}
                onHide={()=>{
                    setCaptchaState(false)
                }}
                onAuth={onAuth}
            />
        </>
    )
}

export function CubeCaptchaModal({api, show = true, onHide = () => {}, onAuth = ()=>{}}: CubeCaptchaModalProps) {
    const [imgSource, setImgSource] = useState("")
    const [captchaPoints, setCaptchaPoints] = useState(new Array<CubeCaptchaPoint>())
    const captchaRef = useRef<HTMLImageElement | null>(null)
    const pointContainerRef = useRef<HTMLDivElement | null>(null)
    const captchaX = () => {
        return Math.floor(captchaRef.current!.getBoundingClientRect().left)
    }
    const captchaY = () => {
        return Math.floor(captchaRef.current!.getBoundingClientRect().top)
    }
    const captchaM = () => {
        return 600 / Math.floor(captchaRef.current!.getBoundingClientRect().width)
    }
    const reloadImage = () => {
        setImgSource(`${api}/image?rand=${Math.floor(Math.random() * 200)}`)
        setCaptchaPoints([])
    }
    useEffect(reloadImage, [api, show])
    return (
        <Modal open={show} onClose={()=>{onHide()}}>
            <Box sx={boxStyle}>
                <Typography variant="h6">
                    请按照语序依次点击文字
                </Typography>
                <img src={imgSource} alt="Captcha服务器遇到了一个问题，请尝试刷新．" ref={captchaRef}
                     style={{width: '100%', marginTop: `${gap}px`}} draggable={false}
                     onClick={(event) => {
                         // console.log(event.clientX - captchaX());
                         // console.log(event.clientY - captchaY());
                         setCaptchaPoints([...captchaPoints, new CubeCaptchaPoint(
                             event.clientX - captchaX(),
                             event.clientY - captchaY(),
                             (event.clientX - captchaX()) * captchaM(),
                             (event.clientY - captchaY()) * captchaM(),
                         )])
                         // console.log(captchaPoints);
                     }}
                     onLoad={() => {
                         pointContainerRef.current!.style.width = `${captchaRef.current?.getBoundingClientRect().width}px`
                         pointContainerRef.current!.style.height = `${captchaRef.current?.getBoundingClientRect().height}px`
                         pointContainerRef.current!.style.top = `${captchaY()}px`
                     }}/>
                <div ref={pointContainerRef} style={{position: 'fixed', pointerEvents: 'none'}}>
                    <AnimatePresence mode="sync">
                    {captchaPoints.map((value, index) => {
                        return (

                                <motion.div
                                    style={{...pointStyle, left: value.left - 13 + "px", top: value.top - 13 + "px"}}
                                    variants={{
                                        hidden: {scale: 0,transition: {duration: 0.3, ease: [0.17, 0.84, 0.44, 1]}},
                                        enter: {scale: 1, transition: {duration: 0.3, ease: [0.17, 0.84, 0.44, 1]}},
                                    }}
                                    initial="hidden"
                                    animate="enter"
                                    exit="hidden"
                                    key={index}>
                                    <Typography variant="subtitle2">{index + 1}</Typography>
                                </motion.div>

                        );
                    })}
                    </AnimatePresence>
                </div>
                <Grid container mt={`${gap}px`}>
                    <Grid item xs sx={{display: 'flex', alignItems: 'center'}}>
                        <Close sx={buttonStyle} onClick={() => {
                            onHide()
                        }}/>
                        <Refresh sx={buttonStyle} onClick={() => {
                            reloadImage()
                        }}/>
                    </Grid>
                    <Button
                        variant="outlined"
                        color="primary"
                        disabled={!(captchaPoints.length>0)}
                        onClick={() => {
                        axios.post(`${api}/push`, JSON.stringify({"data": captchaPoints}), {
                            headers: {
                                'Content-Type': 'application/json;charset=UTF-8'
                            }
                        }).then(r =>{
                            if(r.data.code == 200){
                                onAuth(true)

                                onHide()
                            }else{
                                onAuth(false)
                                reloadImage()
                            }
                        })

                        }}>光速验证</Button>
                </Grid>
            </Box>
        </Modal>
    )
}
